import React, { Component } from "react";
import { Button, Icon, Image, Label } from "semantic-ui-react";
import { saveAs } from "file-saver";

import "./Toolcard.css";

export class Toolcard extends Component {
  state = {};

  download = async (url) => {
    const path = url.split("/");
    const name = path[path.length - 1];
    saveAs(url, name);
  };

  render() {
    const {
      description,
      downloadLink,
      applicationLink,
      githubLink,
      license,
      logoSource,
      tags,
      ui,
      disabled,
    } = this.props.toolInfo;

    return (
      <div className="toolrow">
        <div className="card brand-card">
          <Image
            className="toolcard-brand"
            src={logoSource}
            verticalAlign="middle"
          />
        </div>
        <div className="card description-card">
          <div className="description-row1">
            <h4 className="description-text">{description}</h4>
            {downloadLink && (
              <div className="description-download">
                <Button
                  className="description-github-button"
                  basic
                  compact
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => this.download(downloadLink)}
                  href={githubLink}
                  disabled={disabled}
                >
                  <Icon name="download" /> Download
                </Button>
              </div>
            )}
            <div className="description-github">
              <Button
                className="description-github-button"
                basic
                compact
                target="_blank"
                rel="noopener noreferrer"
                href={githubLink}
                disabled={disabled}
              >
                <Icon name="github" /> GitHub
              </Button>
            </div>
          </div>
          <div className="description-row2">
            <div className="description-tags">
              {tags.map((tag, key) => {
                return <ToolcardTag tag={tag} key={key} />;
              })}
            </div>
            <p className="description-license">{license}</p>
          </div>
        </div>
        <div className="card launch-card">
          {ui ? 
          <Button className="la-button button-launch" href={applicationLink}>
            Launch
          </Button> :
          <Button className="la-button button-launch" href={applicationLink} target="_blank" rel="noopener noreferrer" disabled={disabled}>
            Docs
          </Button>}
        </div>
      </div>
    );
  }
}

class ToolcardTag extends Component {
  render() {
    return (
      <Label className="la-tag" size="tiny">
        {this.props.tag}
      </Label>
    );
  }
}
